.lesson-card {
  border-radius: 1rem;
  padding: 10px;
  margin: 10px;
  background: #0e0e0e;
  animation: popup 0.5s;
  position: relative;
  transition: margin 0.3s;
  p {
    color: white;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }
  .lesson-card-header {
    display: flex;
    justify-content: space-between;
    p {
      font-size: 13px;
      font-weight: 100;
      opacity: 1;
      transition: opacity 0.15s ease;
      margin: 0;
      color: #ffffff94;
    }
    .done {
      display: none;
      color: rgb(93 185 38 / 58%);
    }
  }
  .lesson-title {
    color: #ffffffb8;
  }
  .lesson-type {
    font-size: 14px;
    font-weight: 100;
  }

  &.completed {
    .done {
      display: block;
    }
  }
  &:hover {
    border: rgba(255, 255, 255, 0.158) solid 1px;
    margin: 10px 0px;
    transition: margin 0.3s;
    .lesson-title {
      color: white;
    }
    .lesson-card-header {
      p {
        opacity: 0.3;
        transition: opacity 0.3s;
      }
    }
  }
}

@keyframes popup {
  0% {
    padding: 2px;
  }
  50% {
    padding: 13px;
  }
  100% {
    opacity: 10px;
  }
}
