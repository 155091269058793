.text-to-speech {
  cursor: pointer;
  display: grid;
  justify-content: space-evenly;
  align-items: center;
  min-height: 2rem;
  gap: 5px;
  padding: 5px;
  border: 2px solid rgb(204 204 204 / 27%);
  border-radius: 5px;
  animation: fadein 0.5s;
  transition: border 0.2s;
  &.loading {
    border: solid 2px white;
  }
  svg {
    color: rgba(245, 245, 245, 0.15);
  }
  .loading {
    svg {
      color: white;
    }
    img{
      height: 17px;
      filter: grayscale(1);
    }
  }
  p {
    margin: 0;
  }
  &:hover {
    border: solid 2px white;
    svg {
      color: rgb(255, 255, 255);
    }
    p {
      font-weight: bold;
    }
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
