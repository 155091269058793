$color_3: green;
$color_4: red;
$font-family_1: "Manrope", sans-serif;

@import "https://fonts.googleapis.com/css2?family=Manrope&display=swap";
.flex {
  display: flex;
  align-items: center;
  p {
    margin: 0;
  }
}
body {
  font-family: $font-family_1;
  background: black;
  animation: fadeIn 1s;
  h2 {
    font-size: 2.75rem;
    line-height: 3.25rem;
    font-weight: 700;
    letter-spacing: -1pt;
  }
  p {
    text-align: center;
  }
}
.content-container {
  background: #222327;
  margin: 1rem 2rem;
  padding: 1.5rem;
  border-radius: 2rem;
  color: white;
  min-height: 80vh;
  transition: filter 0.2s;

  &.blur {
    filter: blur(6px);
    pointer-events: none;
  }
  > div {
    animation: unblur 0.3s;
  }
}

.success-feedback {
  color: $color_3;
}
.error-feedback {
  color: $color_4;
}
.lessons {
  display: flex;
  flex-wrap: wrap;
  .lesson-in-level {
    flex-grow: 1;
    flex-basis: 200px;
  }
}
.lesson-type {
  font-weight: bold;
}

nav {
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;

  .flex {
    display: flex;
    align-items: center;

    a {
      padding: 0;
    }
    .logo {
      display: inline-flex;
      align-items: center;
      &.active {
        img {
          filter: contrast(1);
          max-height: 30px;
          transition: max-height 0.2s, filter 0.3s;
        }
      }
      img {
        max-height: 25px;
        margin-right: 5px;
        filter: contrast(0);
        transition: max-height 0.2s, filter 0.3s;
      }
    }
    .user-info {
      .xp {
        .level {
          color: #fff6b1;
        }
      }
    }
    .faq-container {
      ul {
        list-style: hiragana-iroha;
        li {
          margin-bottom: 5%;
          .answer-container {
            max-height: 0;
            overflow: hidden;
            opacity: 0;
            transition: max-height 1s ease, opacity 0.7s ease;

            &.open {
              max-height: 200px;
              opacity: 1;
            }

            &.closed {
              max-height: 0;
              opacity: 0;
            }

            p {
              margin-top: 5%;
              text-align: left;
            }
          }
        }
      }
    }
    .ai-settings {
      select {
        margin: 5px;
        border-radius: 2rem;
      }
    }
  }
  a {
    color: #ffffffa8;
    text-decoration: none;
    margin: 0 10px;
    transition: color 0.3s;
    padding: 5px;
  }

  .icons {
    background: unset;
    font-size: 21px;
    display: flex;
    margin: 2px;
    &:hover {
      background: rgb(255 255 255 / 11%);
    }
    .user-img {
      height: 1.5em;
      width: 1.5em;
      border-radius: 2rem;
    }
  }

  a.active {
    color: #ffffff;
    font-weight: bold;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

  .checkbox-element {
    input[type="checkbox"] {
      height: 0;
      width: 0;
      visibility: hidden;
    }
    label {
      cursor: pointer;
      text-indent: -9999px;
      width: 70px;
      height: 32px;
      background: #80808047;
      display: block;
      border-radius: 100px;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        width: 22px;
        height: 22px;
        background: #fff;
        border-radius: 90px;
        transition: 0.3s;
      }
      &:active {
        &:after {
          width: 60px;
        }
      }
    }
    input {
      &:checked {
        + {
          label {
            background: #3f657d;
            &:after {
              left: calc(100% - 5px);
              transform: translateX(-100%);
            }
          }
        }
      }
    }
  }
}

.extensions-container {
  display: grid;
  .extension {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(1, 1fr);
    justify-items: start;
    margin: 10px;
  }
}
.loading-screen {
  .blob {
    animation: unblur 1s;
    width: 2rem;
    height: 2rem;
    background: rgba(230, 230, 230, 0.85);
    border-radius: 50%;
    position: absolute;
    left: calc(50% - 1rem);
    top: calc(50% - 1rem);
    box-shadow: 0 0 1rem rgba(255, 255, 255, 0.25);
  }

  .blob-2 {
    animation: animate-to-2 1.5s infinite;
  }
  .blob-3 {
    animation: animate-to-3 1.5s infinite;
  }
  .blob-1 {
    animation: animate-to-1 1.5s infinite;
  }
  .blob-4 {
    animation: animate-to-4 1.5s infinite;
  }
  .blob-0 {
    animation: animate-to-0 1.5s infinite;
  }
  .blob-5 {
    animation: animate-to-5 1.5s infinite;
  }

  @keyframes animate-to-2 {
    25%,
    75% {
      transform: translateX(-1.5rem) scale(0.75);
    }
    95% {
      transform: translateX(0rem) scale(1);
    }
  }

  @keyframes animate-to-3 {
    25%,
    75% {
      transform: translateX(1.5rem) scale(0.75);
    }
    95% {
      transform: translateX(0rem) scale(1);
    }
  }

  @keyframes animate-to-1 {
    25% {
      transform: translateX(-1.5rem) scale(0.75);
    }
    50%,
    75% {
      transform: translateX(-4.5rem) scale(0.6);
    }
    95% {
      transform: translateX(0rem) scale(1);
    }
  }

  @keyframes animate-to-4 {
    25% {
      transform: translateX(1.5rem) scale(0.75);
    }
    50%,
    75% {
      transform: translateX(4.5rem) scale(0.6);
    }
    95% {
      transform: translateX(0rem) scale(1);
    }
  }

  @keyframes animate-to-0 {
    25% {
      transform: translateX(-1.5rem) scale(0.75);
    }
    50% {
      transform: translateX(-4.5rem) scale(0.6);
    }
    75% {
      transform: translateX(-7.5rem) scale(0.5);
    }
    95% {
      transform: translateX(0rem) scale(1);
    }
  }

  @keyframes animate-to-5 {
    25% {
      transform: translateX(1.5rem) scale(0.75);
    }
    50% {
      transform: translateX(4.5rem) scale(0.6);
    }
    75% {
      transform: translateX(7.5rem) scale(0.5);
    }
    95% {
      transform: translateX(0rem) scale(1);
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes xpAnimation {
  0% {
    opacity: 1;
    top: 50%;
    left: 50%;
  }
  50% {
    opacity: 1;
    top: 50%;
    left: 50%;
  }
  75% {
    opacity: 0.6;
  }
  100% {
    opacity: 0;
    top: 0%;
    left: 100%;
  }
}
@keyframes unblur {
  0% {
    filter: blur(20px);
    opacity: 0.5;
    pointer-events: none;
  }
  99% {
    pointer-events: none;
  }
  100% {
    filter: blur(0px);
    opacity: 1;
    pointer-events: unset;
  }
}

@media screen and (max-width: 768px) {
  nav {
    flex-wrap: wrap;
    justify-content: center;
  }
  .content-container {
    margin: 1rem 0rem;
    padding: 0.5rem;
    padding-bottom: 4rem;

    &.blur {
      filter: blur(20px);
    }
  }
}
