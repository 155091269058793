.overlay-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &:has(.overlay-content) {
    background-color: #0e0e0e;
    border-radius: 2rem;
  }
  .overlay-content {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0e0e0e;
    border: solid 3px #6d6d6d4d;
    border-radius: 2rem;
    padding: 1rem;
    width: 17rem;
    min-height: 13rem;
    position: absolute;
    top: 120%;
    right: 0%;
    color: white;
    animation: unblur .4s;
    .user-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1em;
      .xp {
        display: flex;
        gap: inherit;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .overlay-container {
    position: unset;
    .overlay-content {
      top: 60%;
      left: 50%;
      transform: translate(-50%, 50%);
      width: 85%;
    }
  }
}

@keyframes unblur {
  0% {
    filter: blur(20px);
    opacity: 0.5;
  }
  100% {
    filter: blur(0px);
    opacity: 1;
  }
}
