/* AddLanguage.css */

.add-language-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 50px;
  border: 1px solid #ccc;
  border-radius: 2rem;
  backdrop-filter: blur(5px);
}

.add-language-container h4 {
  font-size: 20px;
  margin-bottom: 10px;
}
.add-language-container {
  button {
    background: rgba(65, 65, 65, 0.61);
    border: none;
    border-radius: 5px;
    padding: 8px 12px;
    color: white;
    cursor: pointer;
    transition: background 0.3s, transform 0.2s;

    &:hover {
      background: rgba(255, 255, 255, 0.3);
      transform: scale(1.05);
    }
  }
  ul {
    list-style: none;
    padding: 0;
    li {
      background: rgb(65 65 65 / 61%);
      border-radius: 5px;
      padding: 8px 12px;
      margin-bottom: 5px;
      color: white;
      cursor: pointer;
      transition: background 0.3s, transform 0.2s;
      &:hover,
      &.selected {
        background: rgb(0 0 0 / 29%);
        cursor: pointer;
        transform: scale(1.05);
        transition: background 0.3s, transform 0.2s;
      }
    }
  }
}

.language-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-button {
  margin-top: 5%;
  border: 3px solid rgba(147, 255, 205, 0.6588235294) !important;
  width: 100%;
}
