.quiz {
  --body-color: #393937;
  --accent-color: #03e9f4;
  --accent-color2: rgb(24, 129, 134) 4;
  --white-color: #fff;
  --box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4,
    0 0 100px #03e9f4;
  --box-shadow2: 0 0 1px #03e9f4, 0 0 10px #03e9f4, 0 0 20px #03e9f4,
    0 0 100px #03e8f400;

  display: grid;
  justify-items: center;
  gap: 30px;
  .options {
    display: flex;
    justify-content: center;
    padding: 8;
    .option {
      padding: 8px;
      user-select: none;
    }
  }
  span {
    color: white;
    transition: color 0.5s;

    &.dragged-answer {
      font-weight: bold;
      color: gray;
      background: #00000033;
      border: solid 1px #ffffff52;
      border-radius: 5px;
      padding: 2px;
    }
    &.incorrect-answer {
      color: red;
    }
    &.correct-answer {
      color: #00cd00;
    }
  }
  .final-result {
    .correct-text {
      font-size: 110%;
      transition: fade 0.3s;
      text-align: center;
      .correct-option {
        color: #00cd00;
        background: #00000033;
        border: solid 1px #ffffff52;
        border-radius: 5px;
        padding: 2px;
      }
    }
  }
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}
@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}
@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}
@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}
@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(1em) ;
  }
}
