$gradient1: #ff7f00, #ff00ff, #00ff00;
$gradient2: #4caf50, #66bb6a, #abffd5;
$gradient3: #ffc107, #ff5722, #9c27b0;
$gradient4: #333, #777, #bbb;
$gradient5: #ffc0cb, #ffb6c1, #fb500c;
$gradient6: #b3e0e3, #6ca4a9, #3d6d7e;
$gradient7: #8b4513, #cd853f, #deb887;
$gradient-border: linear-gradient(45deg, $gradient1);

.home {
  h2 {
    margin-top: 20px;
  }
  .logo {
    img {
      max-height: 80px;
      filter: drop-shadow(8px 9px 9px black);
    }
  }
  .languages-grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    a {
      text-decoration: none;
    }
  }

  .card-row {
    grid-auto-flow: column;
    grid-template-columns: repeat(3, 1fr);
    min-height: 264px;
    .suggestion-card {
      background: #0e0e0e;
      margin: 1rem;
      padding: 1rem;
      border-radius: 2rem;
      h3 {
        color: white;
        transition: font-size 0.1s;
        .span-highlight {
          background-clip: text;
          -webkit-background-clip: text;
          animation: gradientAnimation 4s infinite linear;
          display: inline-block;
          color: transparent;
          background-size: 200% 100%;

          &.explore {
            background-image: linear-gradient(45deg, $gradient6);
          }
          &.pick {
            background-image: linear-gradient(45deg, $gradient2);
          }
          &.create {
            background-image: linear-gradient(45deg, $gradient5);
          }
        }
        &:hover {
          font-size: 120%;
          transition: font-size 0.3s;
        }
      }
      .buttons-row {
        display: inline-grid;
        button {
          color: white;
          background: #1e1f20;
          border-radius: 2rem;
          margin: 5px;
          text-transform: capitalize;
          &.show-more {
            background: #878787a1;
            color: #ffffffb3;
          }
        }
      }
    }
  }
}
@keyframes gradientAnimation {
  0% {
    background-position: 200% 50%;
  }
  100% {
    background-position: -200% 50%;
  }
}
@keyframes borderAnimation {
  0% {
    opacity: 1;
    background-position: 200% 50%;
  }
  100% {
    opacity: 1;
    background-position: -200% 50%;
  }
}
@media screen and (min-width: 769px) {
  .home .card-row {
    display: grid;
  }
}
@media screen and (max-width: 768px) {
  .logo {
    display: flex;
    justify-content: center;
  }
  h2 {
    font-size: x-large !important;
    text-align: center;
  }
  .home .card-row {
    .suggestion-card {
      margin: 0.5rem;
      padding: 0.5rem;
      .buttons-row {
        display: block;
      }
    }
  }
}
