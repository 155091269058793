.hero {
  display: flex;
  justify-content: center;
  align-items: center;

  .hero-content {
    display: inline-grid;
    justify-items: center;
    .logo {
      display: grid;
      justify-items: center;
      img {
        max-height: 100px;
        animation: logo 0.7s;
        transform: scale(1);
        transition: transform 0.3s;
        &:hover {
          transform: scale(1.06);
          transition: transform 0.3s;
        }
      }
      h2 {
        margin-top: 0;
        text-align: center;
      }
    }
    .languages-row {
      display: flex;
      gap: 10px;
      margin: 30px 0;
      flex-wrap: wrap;
      justify-content: center;
      .language-box {
        img {
          max-height: 60px;
          border: 10px solid #101010;
          border-radius: 4rem;
        }
        p {
          margin: 0;
          text-decoration: none;
          color: white;
        }
      }
    }
    .login-options{
      display: flex;
      align-items: center;
      button{
        display: flex;
        align-items: center;
        svg{
          font-size: 20px;
          margin: 0 4px;
        }
      }
    }
  }
}

@keyframes logo {
  0% {
    opacity: 0.8;
    filter: contrast(0);
    transform: scale(0.8);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    filter: contrast(1);
    transform: scale(1);
  }
}

@media screen and (max-width: 768px) {
  .languages-row{
    .language-box{
      img{
        max-height: 44px;
      }
    }
  }
}