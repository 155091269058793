.page-title {
  display: flex;
  justify-content: center;
  h2 {
    text-transform: capitalize;
    font-size: 2.75rem;
    font-weight: 400;
    line-height: 3.25rem;
    letter-spacing: normal;
    font-weight: 700;
    letter-spacing: -1pt;
  }
}
a {
  text-decoration: none;
}
.lessons {
  div {
    flex-grow: 1;
  }
}
.alphabet {
  span {
    font-weight: bold;
    border-bottom: solid 2px;
    margin: 0 2px;
  }
  .alphabet-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
    gap: 10px;
    padding: 3% 25%;
  }
}
@media screen and (max-width: 768px) {
  .alphabet-grid {
    padding: 0% !important;
  }
}
