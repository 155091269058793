$color_1: white;
$primary-color: 13, 45, 123;
$icon-size: 3rem;
$background-color_1: #007bff;
$background-color_2: #0056b3;
$background-color_3: #f9f9f9;

.tab-navigation {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  button {
    padding: 10px 20px;
    background-color: $background-color_1;
    color: $color_1;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: $background-color_2;
    }
  }
}
.tab-content {
  border: 1px solid #ccc;
  padding: 20px;
  background-color: $background-color_3;
}
.section-title {
  text-align: center;
}

button {
  background: rgb(255 255 255 / 21%);
  border: none;
  border-radius: 2rem;
  padding: 8px 12px;
  color: #ffffff;
  cursor: pointer;
  margin: 0px 5px;
  transition: background 0.3s, transform 0.2s;

  &:hover {
    background: rgba(255, 255, 255, 0.3);
    transform: scale(1.05);
  }
}

select {
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
  color: #ffffff;
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(255, 87, 34, 0.7);
  }
}

option {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  color: #000000;
}

.language-fetch {
  text-align: center;
  .language-select {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    input[type="radio"] {
      display: inline-block;
      position: absolute;
      overflow: hidden;
      clip: rect(0 0 0 0);
      height: 1;
      width: 1;
      margin: -1;
      padding: 0;
      border: 0;
      &:checked ~ .radio {
        background-color: rgb(220 91 255 / 18%);
      }
    }
    .radio {
      display: flex;
      justify-content: center;
      border-radius: 0.5rem;
      padding: 0.5rem;
      &:hover {
        cursor: pointer;
        background-color: rgb(220 91 255 / 18%);
      }
    }

    .language-select-button {
      &.idle {
        animation: moveButton 1.3s ease-in-out infinite;
        border-radius: 2rem;
        &:hover {
          .radio {
            border-radius: 2rem;
          }
        }
      }
    }
  }
}
.lesson-feilds {
  .input-field {
    position: relative;
    width: fit-content;
    margin-bottom: 1rem;
    input {
      width: 350px;
      height: 60px;
      border-radius: 6px;
      font-size: 18px;
      padding: 0 15px;
      border: 2px solid #fff;
      background: transparent;
      color: #fff;
      outline: none;
    }
    textarea {
      border-radius: 6px;
      font-size: 18px;
      padding: 10px 15px;
      border: 2px solid #fff;
      background: transparent;
      color: #fff;
      outline: none;
    }
    label {
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
      color: #fff;
      font-size: 19px;
      pointer-events: none;
      transition: 0.3s;
      border-radius: 1rem;
    }

    textarea:focus,
    input:focus {
      border: 2px solid #ffcc00;
    }
    textarea:focus ~ label,
    textarea:valid ~ label,
    input:focus ~ label,
    input:valid ~ label {
      top: 0;
      left: 15px;
      font-size: 16px;
      padding: 0 5px;
      background: #353842;
    }
  }
  .quiz-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 20px;
    .quiz-options {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      gap: 5px;
      margin-bottom: 4%;
      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: solid 1px;
        border-radius: 1rem;
        padding: 3%;
        p {
          margin: 0;
        }
      }
    }
  }
  .add-lesson-type {
    display: grid;
    justify-items: center;
    align-items: center;
  }
  .add-lesson-button {
    display: flex;
    justify-content: center;
    button {
      border: 3px solid #93ffcda8;
    }
  }
}
.table-container {
  overflow-x: auto;
  table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
    border-radius: 2rem;

    th,
    td {
      border: 1px solid #ddd;
      padding: 8px;
      text-align: center;
      &.main {
        color: palegoldenrod;
      }
      a {
        color: white;
        text-decoration: underline;
      }
      div {
        text-align: center;
        margin-top: 50px;
      }
    }
  }
}

@keyframes moveButton {
  0% {
    margin-left: 0rem;
    margin-right: 0rem;
    border-right: solid 1px rgba(255, 255, 255, 0);
    border-left: solid 1px rgba(255, 255, 255, 0);
  }
  50% {
    margin-left: 0.1rem;
    margin-right: 0.1rem;
    border-right: solid 1px rgba(255, 255, 255, 0.5);
    border-left: solid 1px rgba(255, 255, 255, 0.5);
  }
  100% {
    margin-left: 0rem;
    margin-right: 0rem;
    border-right: solid 1px rgba(255, 255, 255, 0);
    border-left: solid 1px rgba(255, 255, 255, 0);
  }
}

@media screen and (max-width: 768px) {
  .admin-container {
    .tabs-container .tab-switch {
      .tab-indicator {
        display: none;
      }
    }
    .table-container {
      table {
        th,
        td {
          font-size: 11px;
          &.user-id {
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 4rem;
          }
        }
      }
    }
  }
}
