// TabSwithch.scss

.tabs-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;

  .tab-switch {
    position: relative;
    text-align: center;
    background: rgba(0, 0, 0, 0.33);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 2rem;
  }

  .tab-indicator {
    content: "";
    position: absolute;
    width: 14rem;
    top: 0;
    transition: transform cubic-bezier(0.88, -0.35, 0.565, 1.35) 0.4s;
    border-radius: 27.5px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #334e5f;
    height: 100%;
    z-index: 0;
  }

  .tab-switch.left:after {
    left: 0;
  }

  .tab-switch .tab {
    display: inline-block;
    width: 14rem;
    padding: 12px 0;
    z-index: 1;
    position: relative;
    cursor: pointer;
    transition: color 200ms;
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    user-select: none;
    color: #adadadab;
    &:hover {
      color: white;
    }
  }

  .tab-switch .tab.active {
    color: #ffffff;
  }
}

@media screen and (max-width: 768px) {
  .tabs-container .tab-switch {
    backdrop-filter: unset;
    .tab-indicator {
      width: 100%;
      height: 34%;
    }
  }
}
